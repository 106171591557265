import React from "react";
import styled from "styled-components";

const restaurantList = (props) => {
  const locations = props.locations.map((location, index) => {
    const {
      FacilityName,
      ClosureDate,
      Address,
      CityStateZip,
      Violation,
    } = location;
    return (
      <CardwithStyles className="card" key={index}>
        <div className="content">
          <div className="header">{FacilityName}</div>
          <div className="meta date">Date: {ClosureDate}</div>
          {/* <div className="description">
            {Address}
            <div className="description">{CityStateZip}</div>
          </div> */}
          <div className="description">
            <a
              href={`http://maps.google.com/?q=${Address},${CityStateZip}`}
              target="_blank"
            >
              <p className="">{Address}</p>
              <p className="">{CityStateZip}</p>
            </a>
          </div>
          <div className="descriptionViolation">{Violation}</div>
        </div>
      </CardwithStyles>
    );
  });

  return (
    <CardContainer className="ui cards">
      {props.locations.length > 0 ? locations : ` ${props.message}`}
    </CardContainer>
  );
};

const CardwithStyles = styled.div`
  height: 150px !important;

  .descriptionViolation {
    color: #ce2e2e;
    position: absolute;
    bottom: 4px;
    text-align: left;
  }

  .date {
    margin-top: 4px;
  }

  .ui.form {
    width: 45%;
    margin-bottom: 2rem;
    margin: 2rem auto;
  }

  .ui.icon.input {
    width: 60%;
    margin: 2rem auto;
    display: flex;
  }

  .description p {
    color: rgba(0, 0, 0, 0.68) !important;
    margin: 0px !important;
  }
`;

const CardContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 1rem;
`;

export default restaurantList;
