import React, { useState } from "react";
import { Select } from "semantic-ui-react";
import styled from "styled-components";

const searchOptions = [
  {
    key: "city",
    value: "city",
    text: "City",
  },
  {
    key: "name",
    value: "name",
    text: "Name",
  },
];

const SearchSelect = ({ setSearchFilter, searchFilter, filterResults }) => {
  const [searchTerm, setTextSearch] = useState("");

  return (
    <SearchContainer className="ui icon input">
      <div>
        <StyledSearchSelect
          placeholder="Filter"
          options={searchOptions}
          onChange={setSearchFilter}
          value={searchFilter}
        />
      </div>

      <SearchInput
        type="text"
        onChange={e => {
            setTextSearch(e.target.value)
            filterResults(e.target.value)
        }}
        value={searchTerm}
        placeholder={`Search by ${searchFilter}`}
        disabled={!searchFilter}
      ></SearchInput>
    </SearchContainer>
  );
};

const StyledSearchSelect = styled(Select)`
  &&& {
    width: 100px;  
    @media (max-width: 768px) {
      margin-bottom: .5em;
      font-size: 16px;
      width: 100%;
    }
  }
`;

const SearchContainer = styled.div`
  width: 60%;
  margin: 2rem auto;
  display: flex !important;
  @media (max-width: 768px) {
    flex-direction: column;
    width: 80%;
    > div {
      margin-bottom: .5em;
    }
  }
`

const SearchInput = styled.input`
@media (max-width: 768px) {
  font-size: 16px
}
`

export default SearchSelect;
