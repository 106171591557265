export const filterResultsByCity = (locations, city) => {
    return locations.filter((location) => {
      return location.CityStateZip.includes(city)
    });
  };

  export const filterResultsByName = (locations, name) => {
    return locations.filter((location) => {
      return location.FacilityName.includes(name)
    });
  };