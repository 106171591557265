import React from 'react';
import axios from 'axios';
import Card from './Card';
import SearchSelect from './SearchSelect.js';
import { filterResultsByCity, filterResultsByName } from './library/filters';

class App extends React.Component {
  state = { 
    locations: [], 
    results: [], 
    searchFilter: '',
    userMessage: 'Loading...'
  };

  connectToInspectionDatabase = async () => {
    const response = await axios({
      method: 'POST',
      url: 'https://cors-anywhere.herokuapp.com/https://orangeeco.envisionconnect.com/api/pressAgentClient/searchFacilities?PressAgentOid=a14e5b5a-0788-490e-95b5-a70d0172bb3c',
      data: {
        FacilityName: "Orange"
      }
    });

    this.setState({ locations: response.data, results: response.data })
  }

  componentDidMount() {
    this.connectToInspectionDatabase();
  }

  filterResults = (term) => {
    const { locations, results, searchFilter } = this.state;
    const searchTerm = term.toUpperCase();
    let filteredResults;
    searchFilter == 'city' ? filteredResults = filterResultsByCity(locations, searchTerm) : filteredResults = filterResultsByName(locations, searchTerm);
    
    if (filteredResults.length <= 0){
      this.setState({
        userMessage: 'No results found'
      })
    }

    if (searchTerm <= 1) {
      this.setState({ results: this.state.locations })
    } else {
      this.setState({ results: filteredResults })
    }
  };




  setSearchFilter = (e, {name, value} = e.target ) => {
    this.setState({
      searchFilter: value,
    })
  };


  render() {
    const { locations, results, searchFilter, userMessage } = this.state;
    return (
      <div>
        <h1 className='title'>Health Inspection Closings</h1>
        <main>
          <SearchSelect setSearchFilter={this.setSearchFilter} searchFilter={searchFilter} filterResults={this.filterResults}/>
          <Card locations={results} message={userMessage}/>
        </main>
      </div>
        )
    };
}

export default App;


